<template>
  <div style="min-width: 100%" class="shortlists-container" v-if="init">
    <!-- Left sidebar -->
    <div class="shortlists-selection">
      <button @click="createShortlist">
        <span class="material-icons">add</span>
        Create new shortlist
      </button>

      <div class="shortlists-filter">
        <h4>Filter created by</h4>
        <div class="shortlists-dropdown">
          <div
            class="selected"
            @click="dropdownOpen = !dropdownOpen"
            :class="{ open: dropdownOpen }"
          >
            <span v-if="filter === 'all'">All</span>
            <span v-else>{{ getUserNameById(filter) }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="options" v-if="dropdownOpen">
            <div
              class="option"
              @click="selectFilter('all')"
              :class="{ active: 'all' === filter }"
            >
              All
            </div>
            <div
              class="option"
              v-for="option in getAdminUsers"
              :key="'admin-' + option.id"
              @click="selectFilter(option.id)"
              :class="{ active: option.id === filter }"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
      </div>

      <!-- Existing shortlists -->
      <SharedShortlistsSelector />
    </div>

    <!--- Edit shortlist -->
    <div class="shortlist-edit" v-if="selectedShortlist !== null">
      <!-- Header -- Name + Delete TODO: copy -->
      <SharedShortlistsHeader />

      <div class="shortlists-main-area">
        <SharedShortlistsCompanySelection v-if="currentStep === 0" />
        <SharedShortlistsProductSelection v-else-if="currentStep === 1" />
      </div>

      <SharedShortlistsFooter />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SharedShortlists",
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    ...mapState({
      selectedShortlist: (state) => state.sharedShortlists.selectedShortlist,
      loading: (state) => state.sharedShortlists.loading,
      currentStep: (state) => state.sharedShortlists.currentStep,
      init: (state) => state.sharedShortlists.init,
      filter: (state) => state.sharedShortlists.filter,
    }),
    ...mapGetters("sharedShortlists", [
      "currentShortlists",
      "selectedShortlistData",
    ]),
    ...mapGetters("products", [
      "getProductsBySearch",
      "getProductByFirebaseId",
    ]),
    ...mapGetters("companies", [
      "getCompaniesBySearch",
      "getCompanyByFirebaseId",
    ]),
    ...mapGetters("users", ["getUserNameById", "getAdminUsers"]),
  },
  async created() {
    if (!this.init) {
      await this.$store.dispatch("sharedShortlists/bindShortlists");
      this.$store.commit("sharedShortlists/setInit", true);
    }
  },
  async beforeDestroy() {
    this.$store.commit("sharedShortlists/setInit", false);
    await this.$store.dispatch("sharedShortlists/unbindShortlists");
  },
  components: {},
  methods: {
    selectFilter: function (selected) {
      this.dropdownOpen = false;
      this.$store.commit("sharedShortlists/setFilter", selected);
    },
    createShortlist: function () {
      if (this.loading === true) {
        return;
      }
      this.$store.commit("setLoading", true);

      this.$store.dispatch("sharedShortlists/createShortlist").then(() => {
        this.$store.commit("setLoading", false);
      });
    },
  },
};
</script>
